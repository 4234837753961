<template>
  <vue-final-modal
    v-slot="{ close }"
    v-bind="$attrs"
    :z-index-auto="true"
    :lock-scroll="true"
    classes="modal-container"
    content-class="modal-content"
  >
    <div class="modal modal-content">
      <button id="close" :onClick="close" type="button">
        <FontAwesomeIcon :icon="icon" />
        <span class="u-hidden@visually">Schließen</span>
      </button>
      <p>
        Schreiben Sie mir an: <span id="email">{{ email }}</span>
      </p>
      <button :onClick="close">Schließen</button>
    </div>
  </vue-final-modal>
</template>

<script>
import { VueFinalModal } from "vue-final-modal";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
export default {
  components: {
    VueFinalModal,
    FontAwesomeIcon,
  },
  props: {
    email: String,
  },
  data: () => {
    return {
      icon: faTimes,
    };
  },
};
</script>
<style lang="scss" scoped>
@use "../styles/forms";
@use "../styles/variables" as vars;
/*  modal  */

.modal {
  position: relative;
  &.modal-content {
    position: absolute;
    height: auto;
    width: 100vw;
    background-color: vars.$white;
    border-radius: 2px;
    padding: 3rem 1.6rem 1.6rem;
    top: 50%;
    transform: translate(0, -50%);
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    .btn-newsletter {
      padding: 4px 7px;
    }

    #email {
      padding: 0.5rem;
      margin: 0.5rem;
      margin-bottom: 0;
      background-color: #eee;
      text-align: center;
      display: block;
      font-weight: bold;
    }
  }
  #close {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 1.5rem;
    border: none;
    background: none;
    cursor: pointer;
  }
}

@media only screen and (min-width: 580px) {
  .modal.modal-content {
    // height: 14rem;
    width: 22rem;

    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
