<template>
  <main id="main" class="site-main mt8">
    <article class="page">
      <header class="entry-header">
        <h1 class="entry-title">Kontakt</h1>
      </header>

      <div class="entry-content">
        <div>
          <p>
            <span>Elisabeth Hülsdau</span><br />
            <span>Ondrup 41, 59348 Lüdinghausen</span><br />
            <span>Tel.: 02591-88436</span><br />
            <button role="link" class="link link--no-copy" @click="onClick">
              E-Mail
            </button>
          </p>
          <p>
            <span
              >Sie haben Interesse an einem Bild, wünschen weitere Informationen
              oder wollen E-Mails erhalten, wenn Neues ansteht oder neue Bilder
              online sind?</span
            >

            <span>
              Ich helfe ihnen gerne weiter. Schreiben Sie mir einfach eine
              E-Mail oder rufen Sie mich an.
            </span>
          </p>
        </div>
        <!-- {     <Form @submit="submit">
          <div class="field-container">
            <div class="input full">
              <label class="u-hidden@visually" for="name">Name</label
              ><Field
                type="text"
                placeholder="Name *"
                :rules="{ required: true }"
                id="name"
                name="name"
                label="Name"
              />
              <ErrorMessage name="name" />
            </div>
            <div class="input-group">
              <div class="input">
                <label class="u-hidden@visually" for="email">E-Mail</label
                ><Field
                  type="email"
                  placeholder="E-Mail-Adresse *"
                  label="E-Mail"
                  id="email"
                  name="email"
                  :rules="{ required: true, email: true }"
                />
                <ErrorMessage name="email" />
              </div>
              <div class="input">
                <label class="u-hidden@visually" for="telefon"
                  >Telefonnummer</label
                ><Field
                  type="number"
                  :rules="{ regex: /^[0-9]+$/ }"
                  placeholder="Telefonnummer"
                  id="telefon"
                  name="telefon"
                  label="Telefonnummer"
                />
                <ErrorMessage name="telefon" />
              </div>
            </div>
            <div class="input">
              <label for="message" class="u-hidden@visually">Mitteilung</label
              ><Field name="message" v-slot="{ field }">
                <textarea v-bind="field" placeholder="Mitteilung"></textarea>
              </Field>
            </div>
            <div class="input">
              <Field
                v-model="newsletter"
                :value="true"
                :unchecked-value="false"
                type="checkbox"
                id="newsletter"
                name="newsletter"
              /><label for="newsletter"
                >Ja, ich würde gerne E-Mails erhalten, wenn Neues ansteht oder
                neue Bilder online sind.
              </label>
            </div>
            <span id="gdpr"
              >Ich bin mir bewusst, dass meine Daten für den Zweck dieser
              Benachrichtigung gespeichert wird, und erkläre mich damit
              einverstanden.</span
            >
          </div>
          <div>
            <button
              type="submit"
              aria-live="assertive"
              data-alt-text="Sendet..."
              data-submit-text="Senden"
            >
              Senden
            </button>
          </div>
        </Form>} -->
      </div>
      <EmailModal :email="email" v-model="showModal" />
    </article>
  </main>
</template>
<script>
// import { Field, Form, ErrorMessage } from "vee-validate";
import EmailModal from "../components/EmailModal.vue";
export default {
  components: {
    // Field,
    // Form,
    // ErrorMessage,
    EmailModal,
  },
  data() {
    return {
      newsletter: false,
      showModal: false,
      email: null,
    };
  },
  methods: {
    async onClick() {
      try {
        const token = await this.$recaptcha("contact");
        const res = await fetch("/api/email", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ "g-recaptcha-response": token }),
        });

        const { email } = await res.json();
        if (email) {
          this.email = email;
          this.showModal = true;
        }
      } catch (err) {
        console.error(err);
      }
    },
    async submit(values) {
      const token = await this.$recaptcha("contact");
      const body = values;
      body["g-recaptcha-response"] = token;
      await fetch("/api/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
    },
  },
};
</script>

<style lang="scss">
@use "../styles/variables" as vars;
@use "../styles/forms";
/* Kontakt */
article {
  max-width: 600px;
}

/* Forms */

div.forms-container {
  max-width: 600px;
}
</style>
